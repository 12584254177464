@font-face {
  font-family: "sf";
  src: url("../font/SFProDisplay-Regular.woff");
}
:root {
  --font-bg: 7rem;
  --font-md: 5rem;
  --font-md-1: 3.5rem;
  --font-sm: 1.8rem;
  --font-p: 1.8rem;
  --font-a: 2rem;
  --font-ssm: 1.6rem;
  --font-e: 1.5rem;
  --font-height: 3.2rem;
  --mar-sm: 1rem;
  --mar-bg: 2rem;
  --mar-p: 4rem;
  --font-space: "Space Mono";
  --font-inter: "Inter";
  --font-sf: "sf";
}
@media only screen and (max-width: 1260px) and (min-width: 1024px) {
  :root {
    --font-bg: 6rem;
    --font-sm: 1.5rem;
    --font-p: 1.5rem;
    --font-ssm: 1.4rem;
    --font-a: 1.6rem;
    --mar-p: 2rem;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 950px) {
  :root {
    --font-bg: 5rem;
    --font-sm: 1.4rem;
    --font-p: 1.4rem;
    --font-ssm: 1.3rem;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 950px) {
  :root {
    --font-md: 4rem;
  }
}
@media only screen and (max-width: 915px) {
  :root {
    --font-bg: 5rem;
    --font-p: 1.4rem;
    --font-a: 1.2rem;
  }
}
@media only screen and (max-width: 700px) {
  :root {
    --font-bg: 4.5rem;
    --font-sm: 1.3rem;
    --font-p: 1.3rem;
    --font-ssm: 2rem;
    --font-a: 1.4rem;
  }
}
@media only screen and (max-width: 568px) {
  :root {
    --font-md: 3rem;
  }
}
@media only screen and (max-width: 425px) {
  :root {
    --font-bg: 10rem;
    --font-sm: 3rem;
    --font-md: 4rem;
    --font-p: 3rem;
    --font-ssm: 2.5rem;
    --font-a: 3.4rem;
    --font-height: 4rem;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  user-select: none;
  --white: #f7fafa;
  --white-matte: #fff;
  --white-shadow: #a79494;
  --black: #110e0e;
  --black-matte: #000;
  --black-shadow: #0e0b0b;
  --nav: #110e0ed9;
  --card: #242222;
  --hover: #ffffff3d;
  --box: #1919195d;
}
@media only screen and (max-width: 2560px) and (min-width: 1520px) {
  html {
    font-size: 75%;
  }
}
@media only screen and (max-width: 688px) and (min-width: 425px) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 425px) {
  html {
    font-size: 31.25%;
  }
}

html[data-theme=white] {
  --white: #110e0e;
  --white-matte: #000;
  --white-shadow: #0e0b0b;
  --black: #f7fafa;
  --black-matte: #fff;
  --black-shadow: #a79494;
  --nav: #f7fafcd9;
  --card: #e9c7c7;
  --hover: #413a3a8c;
  --box: #f7fafc93;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.header__navbar--navigation__list a {
  font-size: var(--font-sm);
  font-family: var(--font-inter);
  color: var(--white);
}
.header__hero--text-sm {
  font-size: var(--font-sm);
  font-family: var(--font-space);
  color: var(--white);
}
.header__hero--text-bg {
  font-size: var(--font-bg);
  font-family: var(--font-sf);
  color: var(--white-matte);
}
.header__hero--text-p {
  font-size: var(--font-ssm);
  font-family: var(--font-inter);
  line-height: var(--font-height);
  color: var(--white-shadow);
}
.header__hero--text-a {
  background-color: var(--white-matte);
  font-family: var(--font-sf);
  font-size: var(--font-a);
  color: var(--black-matte);
  border-radius: 10px;
}
.header__email--text {
  font-size: var(--font-e);
  color: var(--white-matte);
  letter-spacing: 1.5px;
  font-family: var(--font-inter);
}

.about__text--primary {
  font-size: var(--font-md);
  font-family: var(--font-sf);
  color: var(--white-matte);
}
.about__text--secondary p {
  font-size: var(--font-ssm);
  font-family: var(--font-inter);
  line-height: var(--font-height);
  color: var(--white-shadow);
}

.skills__text--primary {
  font-size: var(--font-md);
  font-family: var(--font-sf);
  color: var(--white-matte);
}
.skills__text--secondary {
  font-size: var(--font-ssm);
  font-family: var(--font-inter);
  line-height: var(--font-height);
  color: var(--white-shadow);
}

.projects__heading {
  font-size: var(--font-md);
  font-family: var(--font-sf);
  color: var(--white-matte);
}
.projects__title {
  font-size: var(--font-md-1);
  font-family: var(--font-sf);
  color: var(--white-matte);
}
.projects__description {
  font-size: var(--font-ssm);
  font-family: var(--font-inter);
  line-height: var(--font-height);
  color: var(--white-shadow);
}
.projects__tech {
  font-size: var(--font-ssm);
  font-family: var(--font-space);
  color: var(--white-matte);
}

.contact__heading {
  font-size: var(--font-bg);
  font-family: var(--font-sf);
  color: var(--white-matte);
}

.footer__text {
  font-size: var(--font-ssm);
  font-family: var(--font-space);
  color: var(--white-matte);
}

.header {
  height: 100vh;
  padding: 0 20rem;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 790px) {
  .header {
    padding: 0 15rem;
  }
}
@media only screen and (max-width: 700px) {
  .header {
    padding: 0;
  }
}
.header__navbar {
  height: 8rem;
  width: 100%;
  background-color: var(--nav);
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 0 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(7px);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px var(--box);
}
@media only screen and (max-width: 425px) {
  .header__navbar {
    height: 13rem;
  }
}
@media only screen and (max-width: 633px) and (min-width: 755px) {
  .header__navbar {
    padding: 0 3rem;
  }
}
.header__navbar--navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header__navbar--navigation__list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
@media only screen and (max-width: 783px) {
  .header__navbar--navigation__list {
    display: none;
  }
}
.header__navbar--navigation__item {
  margin: 0 3rem;
}
.header__navbar--navigation__img {
  padding: 1rem;
  width: 4.3rem;
}
.header__navbar--logo {
  width: 4.1rem;
  height: 4.1rem;
}
@media only screen and (max-width: 755px) {
  .header__navbar--logo {
    width: 5rem;
    height: 5rem;
  }
}
.header__navbar--logo--box {
  width: 100%;
  height: 100%;
}
.header__hero {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}
@media only screen and (max-width: 1158px) {
  .header__hero {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 700px) {
  .header__hero {
    width: 65%;
  }
}
@media only screen and (max-width: 425px) {
  .header__hero {
    width: 100%;
    padding: 0 12rem;
  }
}
@media only screen and (max-width: 332px) {
  .header__hero {
    width: 100%;
    padding: 0 5rem;
  }
}
.header__hero--text {
  text-align: center;
  position: relative;
  z-index: 11;
}
@media only screen and (max-width: 1158px) {
  .header__hero--text {
    margin-left: 0;
  }
}
@media only screen and (max-width: 425px) {
  .header__hero--text {
    text-align: left;
  }
}
.header__hero--text-sm {
  margin-bottom: 1rem;
}
.header__hero--text-bg:last-of-type {
  margin-bottom: 2rem;
}
.header__hero--text-p {
  margin-bottom: 4rem;
  width: 70%;
}
@media only screen and (max-width: 1024px) {
  .header__hero--text-p {
    width: 100%;
  }
}
.header__hero--text-p > a {
  color: inherit;
  text-decoration: underline;
}
.header__hero--text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__hero--text-a {
  padding: 1.5rem;
}
.header__hero--pics {
  width: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  margin-left: 2rem;
}
@media only screen and (max-width: 1158px) {
  .header__hero--pics {
    display: none;
  }
}
.header__hero--pics--each {
  width: 100%;
  backface-visibility: hidden;
  transition: all 0.2s ease-in;
}
.header__hero--pics--each:hover {
  transform: scale(1.05) translateY(-0.5rem);
  z-index: 5;
}
.header__hero--pics--each:hover ~ .header__hero--pics--each:not(:hover) {
  transform: scale(0.95);
  opacity: 0.2;
  z-index: 0;
}
.header__hero--pics--3 {
  position: absolute;
  top: 8.3rem;
  right: 0rem;
  z-index: 0;
}
.header__hero--pics--1 {
  position: absolute;
  top: 6rem;
  right: -3rem;
  z-index: 0;
}
.header__hero--pics--2 {
  position: absolute;
  top: 3.72rem;
  right: -6rem;
  z-index: 0;
}
.header__left--line {
  width: 4rem;
  position: fixed;
  bottom: 0px;
  left: 4rem;
  right: auto;
  z-index: 10;
  color: var(--white);
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .header__left--line {
    width: 50px;
  }
}
@media only screen and (max-width: 847px) {
  .header__left--line {
    display: none;
  }
}
.header__left--line::after {
  content: "";
  display: block;
  width: 2px;
  height: 9rem;
  margin: 0px auto;
  background-color: var(--white);
  border-radius: 50px;
}
.header__social {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
}
@media only screen and (max-width: 532px) {
  .header__social {
    display: none;
  }
}
.header__social--icon {
  margin-bottom: 2.5rem;
}
.header__right--line {
  width: 4rem;
  position: fixed;
  bottom: 0px;
  right: 4rem;
  left: auto;
  z-index: 10;
  color: var(--white);
}
@media only screen and (max-width: 847px) {
  .header__right--line {
    display: none;
  }
}
.header__right--line::after {
  content: "";
  display: block;
  width: 2px;
  height: 9rem;
  margin: 0px auto;
  background-color: var(--white);
  border-radius: 50px;
}
.header__email {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 532px) {
  .header__email {
    display: none;
  }
}
.header__email--text {
  writing-mode: vertical-rl;
  margin: 2rem auto;
}

.home-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bx-home.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.user-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bx-user.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.skills-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bx-cog.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.projects-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bx-code-block.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.contact-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxs-inbox.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.download-icon {
  height: 2.9rem;
  width: 2.9rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/software-download.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

@media only screen and (max-width: 755px) {
  .home-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bx-home.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .user-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bx-user.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .skills-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bx-cog.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .projects-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bx-code-block.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .contact-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxs-inbox.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .download-icon {
    height: 2rem;
    width: 2rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/software-download.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
}
.ig {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-instagram.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.git {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-github.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.link {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-linkedin-square.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.twit {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-twitter.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

@media only screen and (min-width: 2560px) {
  .ig {
    height: 4rem;
    width: 4rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-instagram.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .git {
    height: 4rem;
    width: 4rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-github.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .link {
    height: 4rem;
    width: 4rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-linkedin-square.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .twit {
    height: 4rem;
    width: 4rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-twitter.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
}
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--black);
}

.about {
  min-height: 100vh;
  max-width: 100%;
  margin: 0 18rem;
  padding: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .about {
    margin: 0 10rem;
    padding: 10rem 0;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1080px) {
  .about {
    margin: 0 5rem;
    padding: 5rem 0;
  }
}
@media only screen and (max-width: 1024px) {
  .about {
    margin: 0;
    padding: 0;
  }
}
@media only screen and (max-width: 1158px) {
  .about {
    flex-direction: column;
  }
}
.about__text {
  height: 100%;
  width: 61.5rem;
}
@media only screen and (max-width: 1024px) {
  .about__text {
    width: 60rem;
  }
}
@media only screen and (max-width: 568px) {
  .about__text {
    width: 80%;
  }
}
.about__text--primary {
  margin: 0 0 var(--mar-p) 0;
  display: flex;
  align-items: center;
  position: relative;
}
.about__text--primary::after {
  content: "";
  display: block;
  position: relative;
  width: 30rem;
  height: 3px;
  margin-left: 7rem;
  background-color: var(--white);
}
@media only screen and (max-width: 568px) {
  .about__text--primary::after {
    width: 20rem;
    height: 2px;
  }
}
.about__text--secondary > p:not(:last-of-type) {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 568px) {
  .about__text--secondary > p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
.about__image {
  width: 40rem;
  position: relative;
  z-index: 3;
  margin-right: -2rem;
}
@media only screen and (max-width: 568px) {
  .about__image {
    width: 35rem;
  }
}
@media only screen and (max-width: 568px) {
  .about__image {
    width: 50rem;
  }
}

.skills {
  height: 100vh;
  max-width: 100%;
  margin: 0 12rem;
  padding: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .skills {
    margin: 0 10rem;
    padding: 10rem 0;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1080px) {
  .skills {
    margin: 0 5rem;
    padding: 5rem 0;
  }
}
@media only screen and (max-width: 1024px) {
  .skills {
    margin: 0;
    padding: 0;
  }
}
@media only screen and (max-width: 1158px) {
  .skills {
    flex-direction: column;
  }
}
.skills__text {
  width: 61.5rem;
}
@media only screen and (max-width: 1024px) {
  .skills__text {
    width: 60rem;
  }
}
@media only screen and (max-width: 568px) {
  .skills__text {
    width: 50rem;
  }
}
.skills__text--primary {
  margin: 0 0 var(--mar-p) 0;
  display: flex;
  align-items: center;
  position: relative;
}
.skills__text--primary::after {
  content: "";
  display: block;
  position: relative;
  width: 30rem;
  height: 3px;
  margin-left: 7rem;
  background-color: var(--white);
}
@media only screen and (max-width: 568px) {
  .skills__text--primary::after {
    width: 20rem;
    height: 2px;
  }
}
.skills__text--secondary > p:not(:last-of-type) {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 1152px) {
  .skills__text--secondary > p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1152px) {
  .skills__text--secondary > p:last-of-type {
    margin-bottom: 5rem;
  }
}
.skills__image {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 4.5rem;
  padding: 0 2rem;
}
@media only screen and (max-width: 1152px) {
  .skills__image {
    gap: 3rem;
    padding: 3rem;
    margin-bottom: 2rem;
  }
}
.skills__image--icon {
  width: 8rem;
  opacity: 0.3;
  filter: grayscale(100%) brightness(90%);
  transition: all 0.2s ease-in-out;
}
.skills__image--icon:hover {
  opacity: 1;
  filter: grayscale(0%) brightness(100%);
}

.html5 {
  height: 8rem;
  width: 8rem;
  background-color: #e34f26;
  -webkit-mask-image: url("../svg/white/html5.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.css3 {
  height: 8rem;
  width: 8rem;
  background-color: #1572b6;
  -webkit-mask-image: url("../svg/white/css3.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.js {
  height: 8rem;
  width: 8rem;
  background-color: #f7df1e;
  -webkit-mask-image: url("../svg/white/bxl-javascript.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.sass {
  height: 8rem;
  width: 8rem;
  background-color: #cc6699;
  -webkit-mask-image: url("../svg/white/sass.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.react {
  height: 8rem;
  width: 8rem;
  background-color: #61dafb;
  -webkit-mask-image: url("../svg/white/react.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.node {
  height: 8rem;
  width: 8rem;
  background-color: #339933;
  -webkit-mask-image: url("../svg/white/node-dot-js.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.mongodb {
  height: 8rem;
  width: 8rem;
  background-color: #69b23f;
  -webkit-mask-image: url("../svg/white/mongodb.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.postgresql {
  height: 8rem;
  width: 8rem;
  background-color: #336791;
  -webkit-mask-image: url("../svg/white/postgresql.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.vscode {
  height: 8rem;
  width: 8rem;
  background-color: #007acc;
  -webkit-mask-image: url("../svg/white/visualstudiocode.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.premier {
  height: 8rem;
  width: 8rem;
  background-color: #ea77ff;
  -webkit-mask-image: url("../svg/white/adobepremierpro.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.illustrator {
  height: 8rem;
  width: 8rem;
  background-color: #ff9a00;
  -webkit-mask-image: url("../svg/white/adobeillustrator.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.aftereffects {
  height: 8rem;
  width: 8rem;
  background-color: #9999ff;
  -webkit-mask-image: url("../svg/white/adobeaftereffects.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.projects {
  max-width: 100%;
  margin: 0 18rem;
  padding: 10rem 0;
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .projects {
    margin: 0 10rem;
    padding: 10rem 0;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1080px) {
  .projects {
    padding: 5rem 0;
  }
}
@media only screen and (max-width: 619px) {
  .projects {
    margin: 0 8rem;
  }
}
.projects__heading {
  margin: var(--mar-p) 0;
  display: flex;
  align-items: center;
  position: relative;
}
.projects__heading::after {
  content: "";
  display: block;
  position: relative;
  width: 30rem;
  height: 3px;
  margin-left: 4rem;
  background-color: var(--white);
}
@media only screen and (max-width: 1184px) {
  .projects__heading::after {
    width: 20rem;
    height: 2px;
    margin-left: 2rem;
  }
}
.projects__grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  -webkit-box-align: center;
  align-items: center;
}
@media only screen and (max-width: 1018px) {
  .projects__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.grid:nth-of-type(2n + 1) .projects__content {
  grid-column: 7/-1;
  text-align: right;
}
@media only screen and (max-width: 1200px) {
  .grid:nth-of-type(2n + 1) .projects__content {
    grid-column: 5/-1;
  }
}
@media only screen and (max-width: 1018px) {
  .grid:nth-of-type(2n + 1) .projects__content {
    text-align: center;
  }
}
.projects__content {
  position: relative;
  grid-area: 1/1/-1/7;
}
@media only screen and (max-width: 1018px) {
  .projects__content {
    text-align: center;
  }
}
.projects__title {
  margin-bottom: var(--mar-bg);
  position: relative;
  z-index: 99;
}
.projects__description {
  box-shadow: 0 1rem 3rem -1.5rem #000;
  border-radius: 7px;
  padding: 2rem;
  background-color: var(--card);
  color: var(--white-shadow);
  transition: 0.2s all;
  position: relative;
  z-index: 2;
}
.projects__description:hover {
  box-shadow: 0 2rem 3rem -1.5rem #000;
}
.projects__tech {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  margin: 2.5rem 0rem 1rem;
}
@media only screen and (max-width: 1018px) {
  .projects__tech {
    justify-content: center;
    -webkit-box-pack: center;
  }
}
.projects__tech li {
  margin: 0 2rem 0.5rem 0;
}
@media only screen and (max-width: 1018px) {
  .projects__tech li {
    margin: 0 2rem;
  }
}
.grid:nth-of-type(2n + 1) .projects__tech li {
  margin: 0 0 0.5rem 2rem;
}
@media only screen and (max-width: 1018px) {
  .grid:nth-of-type(2n + 1) .projects__tech li {
    margin: 0 2rem;
  }
}
.grid:nth-of-type(2n + 1) .projects__tech {
  -webkit-box-pack: end;
  justify-content: flex-end;
}
@media only screen and (max-width: 1018px) {
  .grid:nth-of-type(2n + 1) .projects__tech {
    justify-content: center;
    -webkit-box-pack: center;
  }
}
.projects .grid:not(:last-of-type) {
  margin-bottom: 15rem;
}
.grid:nth-of-type(2n + 1) .projects__image {
  grid-column: 1/8;
}
@media only screen and (max-width: 768px) {
  .grid:nth-of-type(2n + 1) .projects__image {
    grid-column: 1/-1;
  }
}
.projects__image {
  grid-area: 1/6/-1/-1;
  position: relative;
  z-index: 1;
}
.projects__image img {
  max-width: 100%;
  vertical-align: middle;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1.03) brightness(90%);
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 1166px) {
  .projects__image img {
    max-width: 80%;
  }
}
@media only screen and (max-width: 1018px) {
  .projects__image img {
    max-width: 100%;
  }
}
.projects__image img:hover {
  transform: scale(1.05);
  filter: grayscale(0%) contrast(1) brightness(100%) drop-shadow(0 0 30px var(--hover));
}

.form {
  max-width: 100%;
  margin: 0 12rem;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form__button {
  margin-top: 40px;
  padding: 13px;
  font-family: var(--font-sf);
  font-size: var(--font-p);
  border: 0;
  border-radius: 10px;
  background-color: var(--white-matte);
  cursor: pointer;
  color: var(--black-matte);
}

.footer {
  text-align: center;
  padding: 20px;
}
.footer__logo--svg {
  width: 3.6rem;
  margin: 0.2rem;
}
.footer__social {
  display: none;
}
@media only screen and (max-width: 850px) {
  .footer__social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
  }
}
.footer__social--icon {
  margin-bottom: 2.5rem;
}

.igF {
  height: 4rem;
  width: 4rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-instagram.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.gitF {
  height: 4rem;
  width: 4rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-github.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.linkF {
  height: 4rem;
  width: 4rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-linkedin-square.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.twitF {
  height: 4rem;
  width: 4rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/bxl-twitter.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.down {
  height: 4rem;
  width: 4rem;
  background-color: var(--white-matte);
  -webkit-mask-image: url("../svg/software-download.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

@media only screen and (max-width: 456px) {
  .igF {
    height: 8rem;
    width: 8rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-instagram.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .gitF {
    height: 8rem;
    width: 8rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-github.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .linkF {
    height: 8rem;
    width: 8rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-linkedin-square.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .twitF {
    height: 8rem;
    width: 8rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/bxl-twitter.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .down {
    height: 8rem;
    width: 8rem;
    background-color: var(--white-matte);
    -webkit-mask-image: url("../svg/software-download.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
}
.donation {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donation__label {
  font-family: var(--font-sf);
  font-size: var(--font-sm);
  color: var(--white-matte);
  margin-bottom: 3rem;
}
.donation__input {
  margin-top: 6px;
  margin-bottom: -5px;
}
.donation__button {
  font-family: var(--font-sf);
  font-size: var(--font-p);
  color: var(--black-matte);
  border: 0;
  border-radius: 10px;
  background-color: var(--white-matte);
  padding: 0.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 20%;
}
.donation__button:hover {
  cursor: pointer;
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1rem;
  max-width: 50rem;
  width: 100%;
  vertical-align: top;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 6rem;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400;
  font-family: var(--font-sf), Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1rem;
  width: 40%;
  color: #696969;
  font-weight: bold;
  font-size: 70.25%;
  font-family: var(--font-sf), Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 16px 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

/* Nao */
.input--nao {
  overflow: hidden;
  padding-top: 2.1rem;
}

.input__field--nao {
  padding: 1rem 0rem 1.87rem;
  width: 100%;
  background: transparent;
  color: #9da8b2;
  font-size: var(--font-p);
}
@media only screen and (max-width: 678px) {
  .input__field--nao {
    padding: 5rem 0rem 2rem;
  }
}

.input__label--nao {
  position: absolute;
  top: 1rem;
  font-size: var(--font-p);
  left: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0rem;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
  transition: transform 0.2s 0.15s, color 1s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
@media only screen and (max-width: 678px) {
  .input__label--nao {
    font-size: 20px;
  }
}

@media only screen and (max-width: 678px) {
  .textarea {
    font-size: 15px;
  }
}

.graphic--nao {
  stroke: #ffaeae;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
  transition: transform 0.7s, stroke 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
  color: var(--white);
  -webkit-transform: translate3d(0, -23px, 0) scale3d(0.8, 0.8, 1);
  transform: translate3d(0, -23px, 0) scale3d(0.8, 0.8, 1);
}
@media only screen and (max-width: 678px) {
  .input__field--nao:focus + .input__label--nao,
  .input--filled .input__label--nao {
    transform: translate3d(0, -10px, 0) scale3d(0.85, 0.85, 1);
  }
}

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
  stroke: var(--white);
  -webkit-transform: translate3d(-66.6%, 0, 0);
  transform: translate3d(-66.6%, 0, 0);
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: background 0.5s ease;
  cursor: pointer;
}
.toggle__image--icon {
  padding-right: 2.8rem;
}
@media only screen and (max-width: 425px) {
  .toggle__image--icon {
    padding-right: 5.5rem;
  }
}

.moon {
  height: 2rem;
  width: 2rem;
  background-color: black;
  -webkit-mask-image: url("../svg/bx-moon.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.sun {
  height: 2rem;
  width: 2rem;
  background-color: white;
  -webkit-mask-image: url("../svg/bx-sun.svg");
  background-size: cover !important;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

@media only screen and (max-width: 425px) {
  .moon {
    height: 4rem;
    width: 4rem;
    background-color: black;
    -webkit-mask-image: url("../svg/bx-moon.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
  .sun {
    height: 4rem;
    width: 4rem;
    background-color: white;
    -webkit-mask-image: url("../svg/bx-sun.svg");
    background-size: cover !important;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
}
.toggle-input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 5.8rem;
  height: 2.8rem;
  background: #c7b5b5;
  float: right;
  border-radius: 100px;
  position: relative;
}
@media only screen and (max-width: 425px) {
  .toggle-label {
    width: 11rem;
    height: 5.8rem;
  }
}

.toggle-label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 2.2rem;
  height: 2.2rem;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
@media only screen and (max-width: 425px) {
  .toggle-label:after {
    top: 4px;
    left: 4px;
    width: 4rem;
    height: 4rem;
  }
}

.toggle-input:checked + .toggle-label {
  background: var(--white-shadow);
}

.toggle-input:checked + .toggle-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggle-label:active:after {
  width: 45px;
}